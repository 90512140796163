import React, { useState, useEffect } from "react";
import BellTopBar from "../../../../Components/Bars/BellTopBar";

import { TableTopBar } from "../../../../Components/FinalTable";

import { useNavigate } from "react-router-dom";

import useAxios from "./../../../../Hooks/useAxios";
import useApp from "../../../../Hooks/useApp";

import TableAll from "./Components/TableComponent/Table";

import AllContext from "./AllContext";

const LiveData = ({ showLive, setShowLive }) => {
  const navigate = useNavigate();
  const { user } = useApp();

  const request = useAxios();

  const { signal, setSignal } = useApp();
  const [changes, setChanges] = useState(0);

  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("desc");
  const [sortField, setSortField] = useState("created_at");
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPrevPage, setHasPrevPage] = useState(false);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);
  const [filters, setFilters] = useState({});

  const [openFilter, setOpenFilter] = useState(false);
  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);

  const [total, setTotal] = useState("");

  useEffect(() => {
    getQueue(1, "created_at", "desc", filters);
  }, [limit]);

  useEffect(() => {
    //console.log(signal);
    if (signal.queue) {
      setChanges((c) => c + 1);
      setSignal({});
    }
  }, [signal]);

  async function getQueue(
    page = 1,
    sort_field = "created_at",
    sort_order = "desc",
    filters = {}
  ) {
    setTableIsLoading(true);

    let res = await request({
      method: "GET",
      url: "hospital/records/queues",
      params: {
        page,
        limit,
        user_id: parseInt(user.id),
        sort_field,
        sort_order,
        ...filters,
      },
      network_error: false,
    });
    setTableIsLoading(false);
    setTotal(res.total_docs);
    if (res === "network-error") {
      setShowLive(false);
      return;
    }

    if (res === "error") return;
    setSignal({});
    setChanges(0);
    setData(res.docs);
    setHasNextPage(res.hasNextPage);
    setHasPrevPage(res.hasPrevPage);
  }

  async function handleNext() {
    if (hasNextPage) {
      setPage(page + 1);
      getQueue(page + 1, sortField, sortOrder, filters);
    }
  }

  async function handlePrev() {
    if (hasPrevPage) {
      setPage(page - 1);
      getQueue(page - 1, sortField, sortOrder, filters);
    }
  }

  async function handleFilterQuery(filters) {
    setSortField("created_at");
    setSortOrder("desc");
    setPage(1);
    getQueue(1, "created_at", "desc", filters);

    setIsFiltered(true);
    setFilters(filters);
  }

  async function handleHeaderQuery(field, order) {
    setPage(1);
    setSortOrder(order);
    setSortField(field);
    getQueue(1, field, order);
  }

  async function handleClearFilters() {
    await getQueue(1, "created_at", "desc", {});
    setPage(1);
    setSortField("created_at");
    setSortOrder("desc");
    setIsFiltered(false);
    setShowPagination(true);
  }

  return (
    <AllContext.Provider
      value={{
        showLive,
        limit,
        setLimit,
        data,
        setData,
        page,
        hasNextPage,
        hasPrevPage,
        handleNext,
        handlePrev,
        handleHeaderQuery,
        handleFilterQuery,
        isFiltered,
        handleClearFilters,
        total: "",
        setIsFiltered,
        showPagination,
      }}
    >
      <div className="w-full h-screen  bg-ghost_white">
        <div className="w-full bg-ghost_white">
          <BellTopBar label="Lab Queue" />
        </div>
        <div className="w-full px-7 pb-8">
          <TableTopBar
            mt={2}
            mb={2}
            showAdd={false}
            changes={changes}
            handleLiveStream={handleClearFilters}
            isFiltered={isFiltered}
            handleClearFilters={handleClearFilters}
            tableIsLoading={tableIsLoading}
            showReport={false}
            showFilter={false}
            showSearch={false}
            showReload={true}
            handleReload={() => getQueue()}
          />
          <TableAll />
        </div>
      </div>
    </AllContext.Provider>
  );
};

export default LiveData;
