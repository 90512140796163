import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { ActionBtn } from "../../../../../../Components/FinalTable";
import { FiFileText, FiFolderPlus } from "react-icons/fi";
import useAxios from "../../../../../../Hooks/useAxios";
import useApp from "../../../../../../Hooks/useApp";
import usePouchCud from "../../../../Hooks/usePouchCud";
import { FaPlus, FaRegFile, FaRegTrashAlt } from "react-icons/fa";
import { TbNurse } from "react-icons/tb";
import TableContext from "./TContext";
import VisitComments from "./Modals/VisitComments";
import VisitDocuments from "./Modals/VisitDocuments";
import ViewComments from "./Modals/ViewComments";
import ViewDocuments from "./Modals/ViewDocuments";
import PerformInvestigation from "./Modals/PerformInvestigation";

const TableActions = ({ doc }) => {
  const [showComments, setShowComments] = useState(false);
  const [showDocuments, setShowDocuments] = useState(false);
  const [showViewComments, setshowViewComments] = useState(false);
  const [showViewDocuments, setShowViewDocuments] = useState(false);
  const [showPerform, setShowPerform] = useState(false);
  const [currState, setCurrState] = useState("");
  const navigate = useNavigate();
  const request = useAxios();
  const { setShowSuccessModal, setModalMessage } = useApp();
  const { investigations, setInvestigations, handleUpdate } =
    useContext(TableContext);
  const { deleteDoc } = usePouchCud();

  const handleDelete = async () => {
    let res = await request({
      method: "DELETE",
      url: `hospital/visit/investigations/${doc?.id}`,
    });

    if (res !== "error") {
      setModalMessage("Investigation Deleted!");
      setShowSuccessModal(true);
      await deleteDoc({ name: "visit_investigation", doc: res });
      handleUpdate();
    }
    let newDocs = investigations.filter((row) =>
      row?.id === doc?.id ? false : true
    );
    setInvestigations(newDocs);
  };

  return (
    <div className="flex-1 bg-white">
      {showPerform && (
        <PerformInvestigation
          doc={doc}
          state={currState}
          showModal={showPerform}
          setShowModal={setShowPerform}
          investigations={investigations}
          setInvestigations={setInvestigations}
          handleUpdate={handleUpdate}
        />
      )}
      <VisitComments
        doc={doc}
        showModal={showComments}
        setShowModal={setShowComments}
        handleUpdate={handleUpdate}
      />
      <VisitDocuments
        doc={doc}
        showModal={showDocuments}
        setShowModal={setShowDocuments}
        handleUpdate={handleUpdate}
      />
      <ViewComments
        doc={doc.visit_comments}
        showModal={showViewComments}
        setShowModal={setshowViewComments}
        handleUpdate={handleUpdate}
      />
      <ViewDocuments
        doc={doc.visit_documents}
        showModal={showViewDocuments}
        setShowModal={setShowViewDocuments}
        handleUpdate={handleUpdate}
      />
      <ActionBtn next={() => navigate(`view/${doc.id}`)} showNext={false}>
        <div className=" gap-y-2 flex flex-col text-sm w-10">
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowComments(true)}
          >
            <div className=" gap-x-2  flex  items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FaPlus />
              </p>
              <p className=" ">Comments</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowDocuments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiFolderPlus />
              </p>
              <p className=" ">Documents</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setshowViewComments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FiFileText />
              </p>
              <p className=" ">Comments</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowViewDocuments(true)}
          >
            <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
              <p className="  text-primary">
                <FaRegFile />
              </p>
              <p className=" ">Documents</p>
            </div>
          </BtnPlain>
          <BtnPlain
            activeBgColor="white"
            bg="white"
            click={() => setShowPerform(true)}
          >
            {doc?.investigation_status !== "complete" ? (
              <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                <p className="  text-red">
                  <TbNurse />
                </p>
                <p>Perform Investigation</p>
              </div>
            ) : null}
          </BtnPlain>
          {/* {doc.investigation_status == "pending" && (
            <>
              <BtnPlain activeBgColor="white" bg="white" click={handleDelete}>
                <div className=" gap-x-2 flex items-center active:opacity-50 cursor-pointer">
                  <p className="  text-red">
                    <FaRegTrashAlt />
                  </p>
                  <p className=" ">Delete </p>
                </div>
              </BtnPlain>
            </>
          )} */}
        </div>
      </ActionBtn>
    </div>
  );
};

export default TableActions;
