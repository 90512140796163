import { useEffect, useState } from "react";
import BellTopBar from "../../../../Components/Bars/BellTopBar";
import PatientDetails from "./components/PatientDetails";
import useAxios from "../../../../Hooks/useAxios";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";

import LoadingModal from "../../../../Components/Modals/LoadingModal";

const Edit = () => {
  const [patient, setPatient] = useState({});
  const [visitDetail, setVisitDetail] = useState({});
  const request = useAxios();
  const { id } = useParams();

  const [patientVitals, setPatientVitals] = useState("___");
  const [patientDetails, setPatientDetails] = useState({});
  const [consultations, setConsultations] = useState([]);
  const [investigations, setInvestigations] = useState([]);
  const [notes, setNotes] = useState([]);

  const [notesV2, setNotesV2] = useState([]);

  const [stepComments, setStepComments] = useState("");
  const [commenter, setCommenter] = useState("");

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  async function Initialize() {
    try {
      setLoading(true);
      let res = await request({
        method: "GET",
        url: `hospital/records/consultation?visit_step_id=${id}`,
      });
      if (res === "error") {
        navigate("/dashboard/user-queue");
        return;
      }
      setConsultations(res?.consultations || []);
      setInvestigations(res?.investigations || []);
      setNotes(res?.notes || []);
      setPatientDetails(res?.visit?.patients || {});
      setPatient(res?.visit?.patients || {});
      setVisitDetail(res?.visit || {});
      setPatientVitals(res?.vitals[0] || "___");
      setPatient(res?.visit?.patients || {});
      setStepComments(res?.visit_step?.comments || "");
      setCommenter(
        res?.visit_step?.team_visit_steps_step_created_byToteam?.name || ""
      );
      setNotesV2(res?.notes_v2 || []);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    Initialize();
  }, []);

  return (
    <div className="w-full h-full bg-ghost_white">
      <div className="mb-4 pb-4">
        <BellTopBar
          label={`${patient.first_name ? patient.first_name : "..."} ${
            patient.second_name ? patient.second_name : ""
          } - Laboratory Form`}
        />
      </div>

      <div className=" ml-5 mt-5 p-5 mb-4 mr-5 bg-white rounded-lg shadow-md ">
        <PatientDetails
          patient={patient}
          setPatient={setPatient}
          patientDetails={patientDetails}
          visitDetail={visitDetail}
          patientVitals={patientVitals}
          consultations={consultations}
          investigations={investigations}
          notes={notes}
          setNotes={setNotes}
          setInvestigations={setInvestigations}
          setPatientDetails={setPatientDetails}
          stepComments={stepComments}
          commenter={commenter}
          Initialize={Initialize}
          notesV2={notesV2}
          setNotesV2={setNotesV2}
        />
      </div>
      <LoadingModal showModal={loading} />
    </div>
  );
};

export default Edit;
