import { IoPartlySunnyOutline } from "react-icons/io5";
import { VscBellDot } from "react-icons/vsc";
import useApp from "../../../../../Hooks/useApp";

import WeatherNavbar from "../../../../Admin/components/Navbar";


function Navbar() {
  const { user } = useApp();

  return (
    <div className="flex justify-between items-center py-4">
      <div>
        <h2 className="text-xl font-DM-Bold text-cadet_grey">
          Welcome back, <span className="text-rose-800">Lab Tech {user.name.split(" ")[0]}!</span>{" "}
        </h2>
      </div>
      <div className="flex">
        <WeatherNavbar />
        <div className="mt-6">
          <span>
            <VscBellDot size="20px" color="red" />
          </span>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
