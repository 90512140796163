import React, { useState } from "react";
import { Table } from "../../../../../../Components/FinalTable";
import usePouchPagination from "../../../../../../Hooks/usePouchPagination";
import TBody from "./TBody";
import THead from "./THeader";
import { useNavigate, useParams } from "react-router-dom";
import BtnPlain from "../../../../../../Components/Buttons/BtnPlain";
import { FaPlus } from "react-icons/fa";
import TableContext from "./TContext";

const InvestigationTable = ({
  investigations,
  setInvestigations,
  handleUpdate,
  visitDetail,
}) => {
  const [limit, setLimit] = useState(20);
  const [data, setData] = useState([]);

  const [vpic, setvPic] = useState(null);
  const navigate = useNavigate();

  return (
    <TableContext.Provider
      value={{
        data: investigations,
        setData: setInvestigations,
        handleUpdate,
        vpic,
        setvPic,
      }}
    >
      <div className="flex flex-col items-start bg-white p-6 rounded-lg mt-6 mb-4">
        <div className="flex flex-row justify-between w-full">
          <p className="font-DM-Med text-lg uppercase text-BLACK">
            Investigations
          </p>
          {/* <div className="">
            {visitDetail?.default_payment_method == "Cash" ? (
              <BtnPlain
                bg="primary"
                textColor="white"
                activeBgColor="secondary"
                mt={2}
                click={() => navigate("add/investigation/cash")}
                className="btn"
              >
                <span className="px-2 font-light text-xl">
                  <FaPlus />
                </span>
                <span className=" font-pop-reg"> Add Investigation Cash</span>
              </BtnPlain>
            ) : visitDetail?.default_payment_method == "Insurance" ? (
              <div className="flex gap-x-5">
                <BtnPlain
                  bg="primary"
                  textColor="white"
                  activeBgColor="secondary"
                  mt={2}
                  click={() => navigate("add/investigation/cash")}
                  className="btn"
                >
                  <span className="px-2 font-light text-xl">
                    <FaPlus />
                  </span>
                  <span className=" font-pop-reg">Add Investigation Cash</span>
                </BtnPlain>

                <BtnPlain
                  bg="primary"
                  textColor="white"
                  activeBgColor="secondary"
                  mt={2}
                  click={() => navigate("add/investigation/insurance")}
                  className="btn"
                >
                  <span className="px-2 font-light text-xl">
                    <FaPlus />
                  </span>
                  <span className=" font-pop-reg">
                    Add Investigation Insurance
                  </span>
                </BtnPlain>
              </div>
            ) : (
              ""
            )}
          </div> */}
        </div>
        <Table>
          <THead />
          <TBody />
        </Table>
      </div>
    </TableContext.Provider>
  );
};

export default InvestigationTable;
