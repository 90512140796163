import React, { useEffect, useState } from 'react'
import { Table } from '../../../../../../../Components/FinalTable'
import usePouchPagination from '../../../../../../../Hooks/usePouchPagination'
import TBody from './TBody'
import THead from './THeader'
import TableContext from './TContext'

const CommentsTable = ({comments, handleUpdate}) => {
  const [limit, setLimit] = useState(30);
  const [data, setData] = useState([]);
  const [showPagination, setShowPagination] = useState(true);

  const [tableIsLoading, setTableIsLoading] = useState(false);
  const [isFiltered, setIsFiltered] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [fixed, setFixed] = useState({});

  const db_name = "visit_comments";

  const initialIndex = ["created_at"];

  const initialSort = [{ created_at: "desc" }];

  const initialSelector = {
    created_at: { $gte: null },
  };

  const {
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    resetQuery,
    page,
    total,
  } = usePouchPagination({
    db_name,
    limit,
    initialSort,
    initialIndex,
    initialSelector,
    // setData,
    setTableIsLoading,
    fixed,
  });

  useEffect(() =>{
    async function initialize(){
      setData(comments)
    }
    initialize()
  },[])


  async function handleClearFilters() {
    await resetQuery();
    setIsFiltered(false);
    setSearchInput("");
  }

return (
  <TableContext.Provider
  value={{
    limit,
    setLimit,
    data,
    comments,
    handleUpdate,
    setData,
    page,
    hasNextPage,
    hasPrevPage,
    handleNext,
    handlePrev,
    handleHeaderQuery,
    handleFilterQuery,
    isFiltered,
    handleClearFilters,
    total,
    setIsFiltered,
    showPagination,
    setFixed,
    resetQuery,
  }}
>
  <div className="flex flex-col items-start bg-white w-full p-6 rounded-lg mt-6 mb-4">
     <Table>
      <THead />
      <TBody />
     </Table>
  </div>
</TableContext.Provider>
)
}

export default CommentsTable