const btns = [
  "Symptoms",
  "Signs",
  "HPI",
  "RDS",
  "Gynecological History",
  "Family Social History",
  "Nutritional History",
  "General Examination",
  "Systemic Examination",
  "Diagnosis",
  "Investigation",
  "Final Diagnosis",
  "Treatment",
];
export default btns;
